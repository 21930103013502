<template>
  <div class="grid-column grid-column_span_5">
    <div class="grid-inner">
      <h3 class="h3 upper" v-html="t('SchemaTitle')"></h3>
      <div v-if="isMobile" class="pm-54"></div>
      <div class="schema">
        <div class="schema__column">
          <div class="schema__item schema__item_type_grid">
            <div v-if="isMobile" class="schema__number">01</div>
            <button v-if="isMobile" class="schema__button" @click="onOpen(1)">
              <span>Qatari-Russian<br />Center for Cooperation</span>
            </button>
            <button v-else class="schema__button" @click="onOpen(3)">
              <span>Qatar-Russia Investment<br />& Trade Advisory</span>
            </button>
            <div v-if="!isMobile" class="schema__popup">
              <div>
                <p class="small">
                  {{ t(`Schema3`) }}
                </p>
                <a
                  class="color-grey"
                  :href="t(`nav_qr_link_investment`)"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ t(`common_website`) }}</a
                >
              </div>
            </div>
          </div>
          <div v-if="isMobile" class="grid-line"></div>
        </div>
        <div class="schema__column">
          <div class="schema__item schema__item_1 schema__item_type_grid">
            <div v-if="isMobile" class="schema__number">02</div>
            <button class="schema__button" @click="onOpen(2)">
              <span>Office<br />of Knowledge<br />and Innovation</span>
            </button>
            <div v-if="!isMobile" class="schema__popup">
              <div>
                <p>
                  {{ t(`Schema2`) }}
                </p>
                <router-link
                  class="color-grey"
                  :to="{
                    name: 'Office',
                    params: paramsLocale,
                  }"
                  >{{ t(`common_website`) }}</router-link
                >
              </div>
            </div>
          </div>
          <div v-if="isMobile" class="grid-line"></div>
          <div class="schema__item schema__item_type_grid">
            <div v-if="isMobile" class="schema__number">03</div>
            <button v-if="isMobile" class="schema__button" @click="onOpen(3)">
              <span>Qatar-Russia Investment<br />& Trade Advisory</span>
            </button>
            <button v-else class="schema__button" @click="onOpen(4)">
              <span>Cultural Creative<br />Agency</span>
            </button>
            <div v-if="!isMobile" class="schema__popup">
              <div>
                <p>
                  {{ t(`Schema4`) }}
                </p>
                <a
                  class="color-grey"
                  :href="t(`nav_qr_link_cultural`)"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ t(`common_website`) }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="schema__column">
          <div v-if="isMobile" class="pm-18"></div>
          <div class="schema__item schema__item_2 schema__item_state_disabled">
            <button v-if="isMobile" class="schema__button" @click="onOpen(4)">
              <span>Cultural Creative<br />Agency</span>
            </button>
            <button v-else class="schema__button" @click="onOpen(1)">
              <span>Qatari-Russian<br />Center for Cooperation</span>
            </button>
            <div v-if="!isMobile" class="schema__popup">
              <div>
                <p>
                  {{ t(`Schema1`) }}
                </p>
                <!-- <a
                  class="color-grey"
                  :href="t(``)"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ t(`common_website`) }}</a
                > -->
              </div>
            </div>
          </div>
          <div v-if="isMobile" class="pm-18"></div>
          <div class="schema__item">
            <button class="schema__button" @click="onOpen(5)">
              <span>QR Media</span>
            </button>
            <div v-if="!isMobile" class="schema__popup">
              <div>
                <p>
                  {{ t(`Schema5`) }}
                </p>
                <a
                  class="color-grey"
                  :href="t(`nav_qr_link_media`)"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ t(`common_website`) }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="schema__column">
          <div v-if="isMobile" class="pm-18"></div>
          <div class="schema__item">
            <button class="schema__button" @click="onOpen(6)">
              <span>Qatar-Russia<br />Experience</span>
            </button>
            <div v-if="!isMobile" class="schema__popup">
              <div>
                <p>
                  {{ t(`Schema6`) }}
                </p>
                <a
                  class="color-grey"
                  :href="t(`nav_qr_link_experience`)"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ t(`common_website`) }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="isMobile" class="grid-line"></div>
        <div class="schema__column">
          <div
            class="schema__item schema__item_position_last schema__item_type_grid"
          >
            <div v-if="isMobile" class="schema__number">04</div>
            <button class="schema__button" @click="onOpen(7)">
              <span>QR Sports</span>
            </button>
            <div v-if="!isMobile" class="schema__popup">
              <div>
                <p>
                  {{ t(`Schema7`) }}
                </p>
                <a
                  class="color-grey"
                  :href="t(`nav_qr_link_sports`)"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ t(`common_website`) }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="isMobile" class="grid-line"></div>
      </div>
    </div>
  </div>
  <transition name="modal-fade">
    <SchemaModal v-if="modalShow" @close="onClose">
      <template #title v-if="modalIndex === 1"
        >Qatari-Russian Center for Cooperation</template
      >
      <template #title v-else-if="modalIndex === 2"
        >Office of Knowledge and Innovation</template
      >
      <template #title v-else-if="modalIndex === 3"
        >Qatar-Russia Investment & Trade Advisory</template
      >
      <template #title v-else-if="modalIndex === 4"
        >Cultural Creative Agency</template
      >
      <template #title v-else-if="modalIndex === 5">QR Media</template>
      <template #title v-else-if="modalIndex === 6"
        >Qatar-Russia Experience</template
      >
      <template #title v-else-if="modalIndex === 7">QR Sports</template>

      <template #desc v-if="modalIndex === 1">{{ t(`Schema1`) }}</template>
      <template #desc v-else-if="modalIndex === 2">{{ t(`Schema2`) }}</template>
      <template #desc v-else-if="modalIndex === 3">{{ t(`Schema3`) }}</template>
      <template #desc v-else-if="modalIndex === 4">{{ t(`Schema4`) }}</template>
      <template #desc v-else-if="modalIndex === 5">{{ t(`Schema5`) }}</template>
      <template #desc v-else-if="modalIndex === 6">{{ t(`Schema6`) }}</template>
      <template #desc v-else-if="modalIndex === 7">{{ t(`Schema7`) }}</template>

      <template #button v-if="modalIndex === 1">
        <!-- <Button class="btn-default-grey" :msg="t(`common_website`)" /> -->
      </template>
      <template #button v-else-if="modalIndex === 2">
        <Button
          class="btn-default-grey"
          :to="{
            name: 'Office',
            params: paramsLocale,
          }"
          :msg="t(`common_website`)"
        />
      </template>
      <template #button v-else-if="modalIndex === 3">
        <Button
          :to="t(`nav_qr_link_investment`)"
          target="_blank"
          class="btn-default-grey"
          :msg="t(`common_website`)"
        />
      </template>
      <template #button v-else-if="modalIndex === 4">
        <Button
          :to="t(`nav_qr_link_cultural`)"
          target="_blank"
          class="btn-default-grey"
          :msg="t(`common_website`)"
        />
      </template>
      <template #button v-else-if="modalIndex === 5">
        <Button
          :to="t(`nav_qr_link_media`)"
          target="_blank"
          class="btn-default-grey"
          :msg="t(`common_website`)"
        />
      </template>
      <template #button v-else-if="modalIndex === 6">
        <Button
          :to="t(`nav_qr_link_experience`)"
          target="_blank"
          class="btn-default-grey"
          :msg="t(`common_website`)"
        />
      </template>
      <template #button v-else-if="modalIndex === 7">
        <Button
          :to="t(`nav_qr_link_sports`)"
          target="_blank"
          class="btn-default-grey"
          :msg="t(`common_website`)"
        />
      </template>
    </SchemaModal>
  </transition>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import en from "@/locales/en.json";
import ru from "@/locales/ru.json";
import ar from "@/locales/ar.json";

import SchemaModal from "@/components/SchemaModal.vue";
import Button from "@/components/Button.vue";

export default defineComponent({
  name: "Schema",
  components: {
    SchemaModal,
    Button,
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
      messages: {
        en,
        ru,
        ar,
      },
    });

    return { t, locale };
  },
  watch: {
    isMobile() {
      this.modalShow = false;
    },
  },
  data() {
    return {
      modalIndex: null,
      modalShow: false,
    };
  },
  methods: {
    onOpen(index) {
      if (!this.isMobile) return;

      this.modalIndex = index;
      this.modalShow = true;
    },
    onClose() {
      this.modalShow = false;
    },
  },
});
</script>

<style scoped lang="scss">
@include mobile {
  .schema {
    &__column {
      text-align: right;
    }
    &__item {
      &_state_disabled {
        pointer-events: none;
      }
      &_type_grid {
        display: grid;
        grid-auto-columns: 1fr auto;
        grid-auto-flow: column;
      }
    }
    &__number {
      font-weight: bold;
      font-size: valM(18);
      line-height: 125%;
      text-align-last: left;
    }
    &__button {
      @include reset-button;
      background: #ffffff;
      width: valM(266);
      height: valM(114);
      border-radius: 52px;
      cursor: default;
    }
  }
}
@include desktop {
  .schema {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: repeat(1fr, 5);
    align-items: end;
    gap: val(30);
    margin-top: val(-26);
    background-image: url("data:image/svg+xml,%0A%3Csvg width='929' height='399' viewBox='0 0 929 399' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='gray' d='M771 245v1H539v-1zM509 245v1H277v-1zM247 245v1H15v-1zM523 256h1v20h-1zM261 256h1v20h-1zM0 256h1v20H0zM786 256h1v20h-1zM929 398v1h-24v-1zM261 81h1v20h-1zM509 116v1H277v-1zM523 0h1v102h-1zM523 131h1v102h-1z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: val(118) val(244);
    background-size: val(929) auto;
    &__item {
      position: relative;
      &_state_disabled {
        pointer-events: none;
      }
      &_1 {
        margin-bottom: val(205);
      }
      &_2 {
        margin-bottom: val(292);
      }
      &_state_open {
        .schema {
          &__button {
            border-radius: val(52) 0 0 val(52);
          }
        }
      }
      &:hover {
        .schema {
          &__button {
            border-radius: val(52) 0 0 val(52);
          }
          &__popup {
            visibility: visible;
            opacity: 1;
          }
        }
      }
      &_position_last {
        &:hover {
          .schema {
            &__button {
              border-radius: 0 val(52) val(52) 0;
            }
            &__popup {
              left: auto;
              right: 100%;
              padding-left: val(20);
              padding-right: val(30);
              border-radius: val(52) 0 0 val(52);
              text-align: right;
              &::before {
                right: val(15);
                left: auto;
              }
            }
          }
        }
      }
    }
    &__button {
      @include reset-button;
      background-color: #fff;
      height: 0;
      width: 100%;
      padding-bottom: 100%;
      border-radius: val(52);
      position: relative;
      display: block;
      cursor: default;
      > span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;
        font-size: val(22);
        line-height: 100%;
      }
    }
    &__popup {
      position: absolute;
      top: 0;
      left: 100%;
      height: 100%;
      width: calc(100% + #{val(30)});
      padding: val(20);
      padding-left: val(30);
      z-index: 1;
      background-color: #fff;
      border-radius: 0 val(52) val(52) 0;
      visibility: hidden;
      opacity: 0;
      &::before {
        position: absolute;
        top: val(15);
        left: val(15);
        height: calc(100% - #{val(30)});
        width: 1px;
        background-color: var(--borderColor);
        content: "";
      }
      > div {
        height: 100%;
        display: grid;
        grid-auto-rows: 1fr auto;
        > p.small {
          font-size: val(14);
        }
      }
    }
  }
}
</style>

<style lang="scss">
html[dir="rtl"] {
  @include desktop {
    .schema {
      background-image: url("data:image/svg+xml,%3Csvg width='929' height='399' viewBox='0 0 929 399' fill='none' xmlns='http://www.w3.org/2000/svg' transform='scale(-1,1)'%3E%3Cpath fill='gray' d='M771 245v1H539v-1zM509 245v1H277v-1zM247 245v1H15v-1zM523 256h1v20h-1zM261 256h1v20h-1zM0 256h1v20H0zM786 256h1v20h-1zM929 398v1h-24v-1zM261 81h1v20h-1zM509 116v1H277v-1zM523 0h1v102h-1zM523 131h1v102h-1z'/%3E%3C/svg%3E");
      background-position: val(236) val(244);
      &__item {
        &_state_open {
          .schema {
            &__button {
              border-radius: 0 val(52) val(52) 0;
            }
          }
        }
        &:hover {
          .schema {
            &__button {
              border-radius: 0 val(52) val(52) 0;
            }
          }
        }
        &_position_last {
          &:hover {
            .schema {
              &__button {
                border-radius: val(52) 0 0 val(52);
              }
              &__popup {
                right: auto;
                left: 100%;
                text-align: left;
                border-radius: 0 val(52) val(52) 0;
                padding-left: val(30);
                &::before {
                  left: val(15);
                  right: auto;
                }
              }
            }
          }
        }
      }
      &__popup {
        right: 100%;
        left: auto;
        padding: val(20);
        padding-right: val(30);
        border-radius: val(52) 0 0 val(52);
        text-align: right;
        &::before {
          right: val(15);
          left: auto;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "SchemaTitle": "STRUCTURE",
    "Schema1": "The Cultural Creative Agency has been established to create and execute strategic representations of cultures, countries, and their people",
    "Schema2": "The Office of Knowledge and Innovation ideates and facilitates collaborations between Qatar and Russia in the fields of education, science, innovation, technology and social issues",
    "Schema3": "The Qatari-Russian Investment & Trade Advisory has been established to forge Qatari-Russian public and private investment and trade partnerships to create economic value for partners and clients",
    "Schema4": "The Cultural Creative Agency has been established to create and execute strategic representations of cultures, countries, and their people",
    "Schema5": "QR Media envisions and creates Qatar-Russia focused media content for partners and the general public",
    "Schema6": "Qatar-Russia Experiences creates and executes unique experiences and tourism opportunities for leisure and business tourists",
    "Schema7": "QR Sports is a sports marketing agency offering consulting and event services for sports entities, businesses, and athletes",
  },
  "ru": {
    "SchemaTitle": "НАША СТРУКТУРА",
    "Schema1": "The Cultural Creative Agency has been established to create and execute strategic representations of cultures, countries, and their people",
    "Schema2": "Office of Knowledge and Innovation (OKI) обеспечивает взаимодействие между Катаром и Россией в сфере образования, науки, инноваций, технологий и в социальной области",
    "Schema3": "Qatari-Russian Investment & Trade Advisory способствует установлению экономически взаимовыгодных партнерских отношений в сфере инвестиций и торговли между государственными и частными организациями в Катаре и России",
    "Schema4": "Cultural Creative Agency реализует стратегические проекты с сфере культуры, направленные на сближение стран и их народов",
    "Schema5": "QR Media знакомит широкую аудиторию и партнеров с Катаром и Россией через различные медиа форматы",
    "Schema6": "Qatar-Russia Experiences создает и реализует уникальные проекты в сфере туризма, отдыха и развлечений",
    "Schema7": "QR Sports – агентство спортивного маркетинга. Мы оказываем консультационные услуги и реализуем проекты для спортивных организаций, коммерческих предприятий и спортсменов",
  },
  "ar": {
    "SchemaTitle": "هيكلتنا",
    "Schema1": "تقوم وكالة الإبداع الثقافي بتنفيذ مشاريع ثقافية استراتيجية تهدف إلى التقريب بين البلدان وشعوبها",
    "Schema2": "يسهل مكتب المعرفة والابتكار (OKI) التفاعل بين قطر وروسيا في مجالات التعليم والعلوم والابتكار والتكنولوجيا والمجالات الاجتماعية",
    "Schema3": "المكتب القطري الروسي للاستثمار والاستشارات التجارية القطرية الروسية يشجع على إقامة شراكات استثمارية وتجارية متبادلة المنفعة بين المؤسسات العامة والخاصة في قطر وروسيا",
    "Schema4": "تقوم وكالة الإبداع الثقافي بتنفيذ مشاريع ثقافية استراتيجية تهدف إلى التقريب بين البلدان وشعوبها",
    "Schema5": "تقوم كيو آر ميديا بالتعريف بقطر وروسيا للجمهور الواسع والشركاء من خلال وسائط إعلامية مختلفة",
    "Schema6": "قطر - روسيا اكسبيرينس تقوم بوضع وتحقيق مشاريع فريدة في قطاع السياحةـ الاستجمام والترفيه",
    "Schema7": "كيو-آر للرياضات هي وكالة للتسويق الرياضي. نقدم خدمات استشارية وننفذ مشاريع للمنظمات الرياضية والشركات والرياضيين.",
  }
}
</i18n>
