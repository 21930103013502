<template>
  <div class="modal">
    <div class="modal__inner">
      <button class="modal__close-mobile" @click="onClose()"></button>
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__body">
            <div class="modal__title"><slot name="title" /></div>
            <div class="modal__desc"><slot name="desc" /></div>
            <div class="modal__button">
              <slot name="button" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__backdrop"></div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "SchemaModal",
  props: {
    isShow: Boolean,
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return { t, locale };
  },
  created() {
    document.addEventListener("keyup", this.onClose);
  },
  unmounted() {
    document.removeEventListener("keyup", this.onClose);
  },
  methods: {
    onClose(e) {
      if (e) {
        if (e.keyCode === 27) this.$emit("close");
      } else {
        this.$emit("close");
      }
    },
  },
});
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: grid;
  place-items: center;
  @include mobile {
    padding: valM(40) valM(25);
  }
  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #808080;
    opacity: 0.4;
    mix-blend-mode: multiply;
  }
  &__inner {
    background: #f3efeb;
    backdrop-filter: blur(50px);
    border-radius: val(32);
    width: val(1070);
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr 1fr;
    padding: val(35) val(30);
    gap: val(30);
    position: relative;
    z-index: 10;
    @include mobile {
      background: #ffffff;
      border-radius: valM(26);
      height: auto;
      max-height: 100%;
      width: 100%;
      overflow: scroll;
      padding: valM(54) valM(18);
      padding-bottom: 0;
    }
  }
  &__pic {
    position: relative;
    img {
      min-height: 100%;
      object-fit: cover;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 100%;
      height: 100%;
      margin-left: val(15);
      width: 1px;
      background-color: var(--borderColor);
      content: "";
    }
  }
  &__content {
    display: grid;
    grid-auto-rows: 1fr auto;
    @include mobile {
      // max-height: 70vh;
      // overflow: auto;
    }
  }
  &__head {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr auto;
    .h3 {
      @include mobile {
        font-size: valM(21);
      }
    }
    p {
      font-size: val(14);
      margin-top: val(2);
      @include mobile {
        font-size: valM(15);
      }
    }
  }
  &__body {
  }
  &__close {
  }
  &__close-mobile {
    @include reset-button;
    position: absolute;
    top: 0;
    right: 0;
    width: valM(64);
    height: valM(64);
    background-image: url("data:image/svg+xml,%0A%3Csvg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='gray' d='M1.3536.6464l12 12M.6464 12.6464l12-12'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: valM(14) valM(13);
    background-position: center;
  }
  &__foot {
    margin-top: val(18);
    @include mobile {
      margin-top: valM(54);
    }
  }
  &__title {
    font-size: valM(21);
  }
  &__desc {
    margin-top: valM(18);
  }
  &__button {
    margin-top: valM(80);
    margin-bottom: valM(24);
  }
}
</style>
