<template>
  <div class="grid-column grid-column_span_2">
    <div v-if="isMobile" class="pm-18"></div>
    <h2 class="h2" v-html="t('at1')"></h2>
  </div>
  <div class="grid-column grid-column_span_3">
    <div v-if="isMobile" class="pm-54"></div>
    <h4 class="h4" v-html="t('at2')"></h4>
  </div>
  <div class="grid-column grid-column_span_5">
    <Promo
      class="promo_full_width"
      :src-pic="image.path"
      :src-pic-mobile="image.mobile_path"
      :src-video="video.path"
      :src-video-mobile="video.mobile_path"
      :is-video="isVideoShow"
    />
  </div>

  <Schema />

  <!-- <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div> -->

  <!-- <div class="grid-column grid-column_row_2">
    <div class="unit">
      <div class="unit__head">
        <div class="h2 upper">QRita</div>
      </div>
      <div v-if="isMobile" class="pm-72"></div>
      <div class="unit__foot">
        <p v-html="t('at4')"></p>
        <div v-if="isMobile" class="pm-18"></div>
        <div>
          <Button
            class="btn-default-grey"
            to="https://ya.com"
            target="_blank"
            msg="Website"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-if="isMobile" class="grid-line"></div>
  <div class="grid-column grid-column_row_2">
    <div class="unit">
      <div class="unit__head">
        <div class="h2 upper">CCA</div>
      </div>
      <div v-if="isMobile" class="pm-72"></div>
      <div class="unit__foot">
        <p v-html="t('at5')"></p>
        <div v-if="isMobile" class="pm-18"></div>
        <div>
          <Button
            class="btn-default-grey"
            to="https://ya.com"
            target="_blank"
            msg="Website"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-if="isMobile" class="grid-line"></div>
  <div class="grid-column grid-column_row_2">
    <div class="unit">
      <div class="unit__head">
        <div class="h2 upper">qr<br />Media</div>
      </div>
      <div v-if="isMobile" class="pm-72"></div>
      <div class="unit__foot">
        <p v-html="t('at6')"></p>
        <div v-if="isMobile" class="pm-18"></div>
        <div>
          <Button
            class="btn-default-grey"
            to="https://ya.com"
            target="_blank"
            msg="Website"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-if="isMobile" class="grid-line"></div>
  <div class="grid-column grid-column_row_2">
    <div class="unit">
      <div class="unit__head">
        <div class="h2 upper">QRE</div>
      </div>
      <div v-if="isMobile" class="pm-72"></div>
      <div class="unit__foot">
        <p v-html="t('at7')"></p>
        <div v-if="isMobile" class="pm-18"></div>
        <div>
          <Button
            class="btn-default-grey"
            to="https://ya.com"
            target="_blank"
            msg="Website"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-if="isMobile" class="grid-line"></div>
  <div class="grid-column grid-column_row_2">
    <div class="unit">
      <div class="unit__head">
        <div class="h2 upper">QR<br />Sports</div>
      </div>
      <div v-if="isMobile" class="pm-72"></div>
      <div class="unit__foot">
        <p v-html="t('at8')"></p>
        <div v-if="isMobile" class="pm-18"></div>
        <div>
          <Button
            class="btn-default-grey"
            to="https://ya.com"
            target="_blank"
            msg="Website"
          />
        </div>
      </div>
    </div>
  </div> -->

  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column">
    <div class="content-pattern content-pattern_type_2"></div>
  </div>

  <Team :team="persons" />

  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>

  <News />
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import en from "@/locales/en.json";
import ru from "@/locales/ru.json";
import ar from "@/locales/ar.json";

import { GetAbout } from "@/api/Pages";

import Promo from "@/components/Promo.vue";
import Team from "@/components/Team.vue";
import News from "@/components/News.vue";
// import Button from "@/components/Button.vue";
import Schema from "@/components/Schema.vue";

export default defineComponent({
  name: "About",
  components: {
    Promo,
    Team,
    News,
    // Button,
    Schema,
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
      messages: {
        en,
        ru,
        ar,
      },
    });

    return { t, locale };
  },
  data() {
    return {
      video: {},
      image: {},
      isVideoShow: false,
      persons: [],
    };
  },
  watch: {
    locale() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.$progress.start();
      try {
        const resp = await GetAbout();
        const {
          image = {},
          show_video = false,
          video = {},
          persons = [],
        } = resp.data.data;

        this.persons = persons;
        this.isVideoShow = show_video;
        this.video = video;
        this.image = image;

        this.$progress.finish();
      } catch (error) {
        this.$progress.finish();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.unit {
  display: grid;
  @include desktop {
    grid-auto-rows: 1fr 1fr;
  }
  &__head {
    @include mobile {
      .h2 {
        font-size: valM(60);
      }
    }
  }
  &__foot {
    @include desktop {
      display: grid;
      grid-auto-rows: 1fr auto;
    }
    .btn {
      @include desktop {
        margin-top: val(18);
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "at1": "ABOUT QRCC GROUP",
    "at2": "Promoting multi-faceted and cross-sectoral bilateral relations, the Qatari-Russian Center for Cooperation (QRCC) works to sustainably grow the bilateral landscape between the two nations.",
    "at3": "STRUCTURE",
    "at4": "The Qatari-Russian Investment & Trade Advisory has been established to forge Qatari-Russian public and private investment and trade partnerships to create economic value for partners and clients",
    "at5": "The Cultural Creative Agency has been established to create and execute strategic representations of cultures, countries, and their people",
    "at6": "QR Media envisions and creates Qatar-Russia focused media content for partners and the general public",
    "at7": "Qatar-Russia Experiences creates and executes unique experiences and tourism opportunities for leisure and business tourists",
    "at8": "QR Sports is a sports marketing agency offering consulting and event services for sports entities, businesses, and athletes",
  },
  "ru": {
    "at1": "ГРУППА КОМПАНИЙ QRCC",
    "at2": "Катарско-российский центр сотрудничества (QRCC) способствует устойчивому развитию отношений между двумя странами по различным направлениям двустороннего сотрудничества.",
    "at3": "НАША СТРУКТУРА",
    "at4": "Qatari-Russian Investment & Trade Advisory способствует установлению экономически взаимовыгодных партнерских отношений в сфере инвестиций и торговли между государственными и частными организациями в Катаре и России",
    "at5": "Cultural Creative Agency реализует стратегические проекты с сфере культуры, направленные на сближение стран и их народов",
    "at6": "QR Media знакомит широкую аудиторию и партнеров с Катаром и Россией через различные медиа форматы",
    "at7": "Qatar-Russia Experiences создает и реализует уникальные проекты в сфере туризма, отдыха и развлечений",
    "at8": "QR Sports – агентство спортивного маркетинга. Мы оказываем консультационные услуги и реализуем проекты для спортивных организаций, коммерческих предприятий и спортсменов.",
  },
  "ar": {
    "at1": "عن مركز التعاون القطري الروسي",
    "at2": "يساهم مركز التعاون القطري الروسي في التنمية المستدامة للعلاقات بين البلدين في مختلف مجالات التعاون الثنائي.",
    "at3": "هيكلتنا",
    "at4": "المكتب القطري الروسي للاستثمار والاستشارات التجارية القطرية الروسية يشجع على إقامة شراكات استثمارية وتجارية متبادلة المنفعة بين المؤسسات العامة والخاصة في قطر وروسيا",
    "at5": "تقوم وكالة الإبداع الثقافي بتنفيذ مشاريع ثقافية استراتيجية تهدف إلى التقريب بين البلدان وشعوبها",
    "at6": "تقوم كيو آر ميديا بالتعريف بقطر وروسيا للجمهور الواسع والشركاء من خلال وسائط إعلامية مختلفة",
    "at7": "قطر - روسيا للخبرات تقوم بوضع وتحقيق مشاريع فريدة في قطاع السياحةـ الاستجمام والترفيه",
    "at8": "كيو-آر للرياضات هي وكالة للتسويق الرياضي. نقدم خدمات استشارية وننفذ مشاريع للمنظمات الرياضية والشركات والرياضيين.",
  }
}
</i18n>
